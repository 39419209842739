.login-card {
  margin-bottom: 0.7rem !important;
}

.logout-modal {
  position: relative !important;
  box-sizing: border-box !important;
  grid-template-columns: minmax(0,100%) !important;
  width: 25em !important;
  max-width: 100% !important;
  padding: 0 0 1.5em !important;
  border: none !important;
  border-radius: 5px !important;
  background: #fff !important;
  color: #545454 !important;
  font-family: inherit !important;
  font-size: 0.8rem !important;
}

.btnCss {
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.iconCss {
  color: theme-color(success);
  font-size: 20px;
}

.reloadCss {
  color: theme-color(success);
  font-size: 24px;
}

.sortCss {
  font-size: 20px;
}

.modal-body {
  position: relative !important;
  flex: 1 1 auto !important;
  padding: 0px !important;
}

.modal-card-header-cls {
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 0 !important;
  background-color: #1f1f38 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.modal-content {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  pointer-events: auto !important;
  background-color: #ecf0f4 !important;
  background-clip: padding-box !important;
  border: 0px solid black !important;
  border-radius: 0.3rem;
  outline: 0;
}
.custom-modal-md {
  width: 60vw;    /* Occupy the 90% of the screen width */
  max-width: 60vw;
}

.custom-modal-sm {
  width: 30vw;   
  max-width: 30vw;
}

.react-tel-input .form-control {
  position: relative !important;
    font-size: 14px !important;
    letter-spacing: .01rem !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important ;
    padding-left: 48px !important;
    margin-left: 0 !important;
    background: #FFFFFF !important;
    border: 1px solid #e8e5e5 !important;
    border-radius: 5px !important;
    line-height: 25px !important;
    height: 43px !important;
    width: 100% !important;
    outline: none;
}
.css-ntpqhw-ToastContainer {
  box-sizing: border-box !important;
  max-height: 100% !important;
  max-width: 100% !important;
  overflow: hidden !important;
  padding: 8px !important;
  position: fixed !important;
  z-index: 1800 !important;
  top: 0 !important;
  right: 0 !important;
}

.css-jfeqr5 {
  box-sizing: border-box !important;
  max-height: 100% !important;
  max-width: 100% !important;
  overflow: hidden !important;
  padding: 8px !important;
  position: fixed !important;
  z-index: 10000 !important;
  top: 0px !important;
  right: 0px !important;
}

.disable-link {
  pointer-events: none !important;
}

.paginationV2 {
  /* margin: 15px auto; */
  display: flex;
  list-style: none;
  outline: none;
}
.paginationV2 > .active > a{
  background-color: theme-color(success) ;
  border-color: theme-color(success) ;
  color: #fff;
}
.paginationV2 > li > a{
  border: 0.2px solid theme-color(success) ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  font-size: 12px;
}
.paginationV2 > .active > a, .paginationV2 > .active > span, .paginationV2 > .active > a:hover, .paginationV2 > .active > span:hover, .paginationV2 > .active > a:focus, .paginationV2 > .active > span:focus{
  background-color: theme-color(success) ;
  border-color: theme-color(success);
  outline: none ;
}
.paginationV2 > li > a, .paginationV2 > li > span{
  color: theme-color(success)
}
.paginationV2 > li:first-child > a, .paginationV2 > li:first-child > span, .paginationV2 > li:last-child > a, .paginationV2 > li:last-child > span{
  border-radius: unset
}
.card-footer {
  padding: 0.75rem 1.25rem !important;
  background-color: #fff !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.loader-footer {
  padding: 3rem 1.25rem !important;
  background-color: #fff !important;
  // border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.small-btn {
  font-size: 0.875rem !important;
  line-height: 0.7 !important;
  font-weight: 700 !important;
  margin-right: 3px !important;
}

.h3-old {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
}

.table-column {
  vertical-align: middle !important;
  font-size: 0.875rem !important;
  line-height: 0.4 !important;
  white-space: nowrap !important;
}

.backgoud-img {
  background: url('../../assets/images/bg-img.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.track-modal-center-div {
  /* padding: 25px; */
  margin-left: 25%;
  margin-right: 25%;
  min-height: 100px;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  //your styles here
  .custom-modal-md  {
    width: 90vw;    /* Occupy the 90% of the screen width */
    max-width: 90vw;
  }
  .custom-modal-sm  {
    width: 60vw;    /* Occupy the 90% of the screen width */
    max-width: 60vw;
  }
}
.css-yk16xz-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 1px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  min-height: 2.575rem !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  font-size: small;
}

.navbar-pop-up {
  font-size: 0.9rem !important;
  margin-top: 15px !important;
  padding: 0 !important;
  width: 230px !important;
}

.admin-img {
  width: 103px;
  height: 103px;
}

.vehicle-registration-label {
  padding-right: 0px !important;
}

.year-picker {
  position: relative !important;
  outline: none !important;
  width: 100% !important;
  list-style: none !important;
  font-size: 14px !important;
  text-align: left !important;
  background-color: #fff !important;
  border-radius: 0px !important;
  background-clip: padding-box !important;
  line-height: 1.5 !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  border: 1px solid transparent !important;
  border-color: #d9d9d9 !important;
  height: 2.575rem !important;
  padding: 0.5rem 0.81rem !important;
}

.label-sm {
  font-size: small;
}

.label-md {
  font-size: 13px;
}

.linkDisabled {
  pointer-events: none;
  cursor: default;
}

.center-div {
  // border: 1px solid rgba(0, 0, 0, 0.10);
  /* padding: 25px; */
  margin-left: 15%;
  margin-right: 15%;
  min-height: 100px;
}

.center-div-2 {
  // border: 1px solid rgba(0, 0, 0, 0.10);
  /* padding: 25px; */
  margin-left: 10%;
  margin-right: 10%;
  min-height: 100px;
}

.react-tel-input :disabled {
  /* cursor: not-allowed; */
  background-color: #e9ecef !important;
}

.logo-cls {
  width: 90px;
  height: 70px;
}

.card-cls {
  min-height: 500px;
}

.auth-label {
  font-size: 13px;
}

.table-cls {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  font-size: 0.65 !important;
  // text-transform: uppercase !important;
  letter-spacing: 0px !important;
  border-bottom: 1px solid #e9ecef !important;
  border-left: 1px solid #e9ecef !important;
  border-right: 1px solid #e9ecef !important;
}

.table-head-cls {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  font-size: 0.65 !important;
  // text-transform: uppercase !important;
  letter-spacing: 0px !important;
  border-top: 1px solid #e9ecef !important;
  border-bottom: 1px solid #e9ecef !important;
  border-left: 1px solid #e9ecef !important;
  border-right: 1px solid #e9ecef !important;
  background-color: #b7b7b7eb !important;
}

.react-datetime-picker__wrapper {
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  height: 2.575rem !important;
  border-radius: 0.2rem !important;
  border: 1px solid #e8e5e5 !important;
  font-weight: 400 !important;
  font-size: 0.8125rem !important;
}

.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2) !important;
  flex-grow: 1 !important;
  padding: 0.5rem 0.81rem !important;
}

.progressBarWrapper {
  margin: 50px;
  margin-bottom: 70px;
}

.trackProgressBarWrapper {
  margin: 50px;
  margin-bottom: 70px;
  margin-left: 20%;
  margin-right: 20%;
}
.center-loader { 
  margin-left: 50%;
  margin-right: 50%;
  margin-top: 10%;
  // margin: auto;
}

.country-select {
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
}

.css-yk16xz-control {
  align-items: center !important;
  background-color: white !important;
  border-color: #e8e5e5 !important;
  border-radius: 1px !important;
  border-style: solid !important;
  border-width: 1px !important;
  cursor: default !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  min-height: 38px !important;
  outline: 0 !important;
  min-height: 2.575rem !important;
  position: relative !important;
  transition: all 100ms !important;
  box-sizing: border-box !important;
  font-size: small !important;
}

.css-1pahdxg-control {
  -webkit-box-align: center !important;
  align-items: center !important;
  background-color: hsl(0,0%,100%) !important;
  border-color: transparent !important;
  box-shadow: 0 0 0 1px #e8e5e5  !important;
  border-radius: 0px !important;
}

.css-17rus17-ValueContainer {
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  padding: 2px 8px !important;
  -webkit-overflow-scrolling: touch !important;
  position: relative !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  font-size: small !important;
}

@media only screen and (max-width: 480px) {
  .custom-modal-md  {
    width: 90vw;    /* Occupy the 90% of the screen width */
    max-width: 90vw;
  }
  .custom-modal-sm  {
    width: 90vw;    /* Occupy the 90% of the screen width */
    max-width: 90vw;
  }
  .progressBarWrapper {
    margin: 10px;
    margin-bottom: 70px;
  }
  .trackProgressBarWrapper {
    margin: 10px;
    // margin-right: 10%;
    // margin-left: 10%;
    margin-bottom: 70px;
  }
  
}